// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as City from "../../../models/City.res.js";
import * as Role from "../../../models/Role.res.js";
import * as User from "../../../models/User.res.js";
import * as $$Image from "../../../models/Image.res.js";
import * as State from "../../../models/State.res.js";
import * as React from "react";
import * as Country from "../../../models/Country.res.js";
import * as $$Location from "../../../models/Location.res.js";
import * as Provider from "../../../models/Provider.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Breadcrumb from "../../../styleguide/components/Breadcrumb/Breadcrumb.res.js";
import * as Routes_City from "../../../routes/common/Routes_City.res.js";
import * as ImageGallery from "../../../styleguide/components/ImageGallery/ImageGallery.res.js";
import * as ProviderUser from "../../../models/ProviderUser.res.js";
import * as Routes_State from "../../../routes/common/Routes_State.res.js";
import * as Routes_Country from "../../../routes/common/Routes_Country.res.js";
import * as Routes_Location from "../../../routes/common/Routes_Location.res.js";
import * as LocationBrochure from "../../../models/LocationBrochure.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationShowScss from "./LocationShow.scss";
import * as LocationShowContact from "./components/contact/LocationShowContact.res.js";
import * as LocationShowSidebar from "./components/sidebar/LocationShowSidebar.res.js";
import * as LocationShowMainContent from "./components/main-content/LocationShowMainContent.res.js";
import * as LocationShowRelatedLocations from "./components/related-locations/LocationShowRelatedLocations.res.js";

var css = LocationShowScss;

var initialState = {
  imagesVisibility: "Hidden"
};

function LocationShow$LocationShow(props) {
  var setUserData = props.setUserData;
  var userLoginStatus = props.userLoginStatus;
  var isMobile = props.isMobile;
  var locationBrochures = props.locationBrochures;
  var relatedLocations = props.relatedLocations;
  var images = props.images;
  var city = props.city;
  var countryState = props.countryState;
  var country = props.country;
  var provider = props.provider;
  var $$location = props.location;
  var reducer = function (state, action) {
    return {
            imagesVisibility: state.imagesVisibility === "Visible" ? "Hidden" : "Visible"
          };
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var userRole = Role.fromString(props.userRole);
  var providerRole = ProviderUser.stringToRole(props.providerRole);
  var tmp;
  switch (userRole) {
    case "Admin" :
        tmp = JsxRuntime.jsx(Breadcrumb.make, {
              breadcrumbLinks: [
                {
                  linkName: "Home",
                  linkPath: "/"
                },
                {
                  linkName: "All Data Centers",
                  linkPath: Routes_Location.index
                },
                {
                  linkName: country.name,
                  linkPath: Routes_Country.show(country.slug)
                },
                {
                  linkName: countryState.name,
                  linkPath: Routes_State.show(countryState.slug, country.slug)
                },
                {
                  linkName: city.name,
                  linkPath: Routes_City.show(city.slug, countryState.slug, country.slug)
                },
                {
                  linkName: $$location.name,
                  linkPath: $$location.url
                },
                {
                  linkName: "Edit",
                  linkPath: Routes_Location.Dashboard.edit($$location.id, provider.slug)
                }
              ],
              wrapperContainerClassName: css.breadcrumbs,
              breadcrumbLinkClassName: css.breadcrumbLink
            });
        break;
    case "Provider" :
        var exit = 0;
        switch (providerRole) {
          case "Owner" :
          case "Manager" :
              exit = 1;
              break;
          case "Member" :
          case "Other" :
              tmp = null;
              break;
          
        }
        if (exit === 1) {
          tmp = JsxRuntime.jsx(Breadcrumb.make, {
                breadcrumbLinks: [
                  {
                    linkName: "Home",
                    linkPath: "/"
                  },
                  {
                    linkName: "All Data Centers",
                    linkPath: Routes_Location.index
                  },
                  {
                    linkName: country.name,
                    linkPath: Routes_Country.show(country.slug)
                  },
                  {
                    linkName: countryState.name,
                    linkPath: Routes_State.show(countryState.slug, country.slug)
                  },
                  {
                    linkName: city.name,
                    linkPath: Routes_City.show(city.slug, countryState.slug, country.slug)
                  },
                  {
                    linkName: $$location.name,
                    linkPath: $$location.url
                  },
                  {
                    linkName: "Edit",
                    linkPath: Routes_Location.Dashboard.edit($$location.id, provider.slug)
                  }
                ],
                wrapperContainerClassName: css.breadcrumbs,
                breadcrumbLinkClassName: css.breadcrumbLink
              });
        }
        break;
    default:
      tmp = JsxRuntime.jsx(Breadcrumb.make, {
            breadcrumbLinks: [
              {
                linkName: "Home",
                linkPath: "/"
              },
              {
                linkName: "All Data Centers",
                linkPath: Routes_Location.index
              },
              {
                linkName: country.name,
                linkPath: Routes_Country.show(country.slug)
              },
              {
                linkName: countryState.name,
                linkPath: Routes_State.show(countryState.slug, country.slug)
              },
              {
                linkName: city.name,
                linkPath: Routes_City.show(city.slug, countryState.slug, country.slug)
              },
              {
                linkName: $$location.name,
                linkPath: $$location.url
              }
            ],
            wrapperContainerClassName: css.breadcrumbs,
            breadcrumbLinkClassName: css.breadcrumbLink
          });
  }
  var match$1 = relatedLocations.totalLocations;
  var match$2 = match[0].imagesVisibility;
  var tmp$1;
  tmp$1 = match$2 === "Visible" ? JsxRuntime.jsx(ImageGallery.make, {
          images: images,
          onClick: (function (param) {
              dispatch("ToggleImagesVisibility");
            })
        }) : null;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      tmp,
                                      isMobile ? JsxRuntime.jsx(H1.make, {
                                              id: "locationName",
                                              className: css.locationName,
                                              children: provider.name + ": " + $$location.name
                                            }) : null,
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(LocationShowSidebar.make, {
                                                  location: $$location,
                                                  provider: provider,
                                                  locationBrochures: locationBrochures,
                                                  userLoginStatus: userLoginStatus,
                                                  setUserData: setUserData,
                                                  onClick: (function (param) {
                                                      dispatch("ToggleImagesVisibility");
                                                    })
                                                }),
                                            className: css.sidebar,
                                            id: "sidebar"
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(LocationShowMainContent.make, {
                                                  location: $$location,
                                                  locationBrochures: locationBrochures,
                                                  provider: provider,
                                                  country: country,
                                                  city: city,
                                                  countryState: countryState,
                                                  images: images,
                                                  userRole: userRole,
                                                  providerRole: providerRole,
                                                  isMobile: isMobile,
                                                  userLoginStatus: userLoginStatus,
                                                  setUserData: setUserData
                                                }),
                                            className: css.mainContent,
                                            id: "mainContent"
                                          })
                                    ],
                                    className: css.locationDetails,
                                    id: "locationDetails"
                                  }),
                              className: css.leftContainer,
                              id: "leftContainer"
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(LocationShowContact.make, {
                                          provider: provider,
                                          location: $$location
                                        }),
                                    className: css.contact,
                                    id: "contact"
                                  }),
                              className: css.rightContainer
                            })
                      ],
                      className: css.topContainer,
                      id: "topContainer"
                    }),
                match$1 !== 0 ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(LocationShowRelatedLocations.make, {
                                    locationId: $$location.id,
                                    provider: provider,
                                    country: country,
                                    relatedLocations: relatedLocations
                                  }),
                              className: css.relatedLocationsContainer,
                              id: "relatedLocationsContainer"
                            }),
                        className: css.bottomContainer,
                        id: "bottomContainer"
                      }) : null,
                tmp$1
              ],
              className: css.container,
              id: "container"
            });
}

var LocationShow = {
  css: css,
  initialState: initialState,
  make: LocationShow$LocationShow
};

function LocationShow$default(props) {
  var props$1 = props.props;
  return JsxRuntime.jsx(LocationShow$LocationShow, {
              location: $$Location.Show.fromJs(props$1.location),
              provider: Provider.fromJs(props$1.provider),
              country: Country.fromJs(props$1.country),
              countryState: State.fromJs(props$1.countryState),
              city: City.fromJs(props$1.city),
              images: Belt_Array.map(props$1.images, $$Image.fromJs),
              relatedLocations: $$Location.RelatedLocations.fromJs(props$1.relatedLocations),
              locationBrochures: Belt_Array.map(props$1.locationBrochures, LocationBrochure.DropdownItem.fromJs),
              isMobile: props$1.isMobile,
              userLoginStatus: User.LoginStatus.fromBool(props.reduxProps.isUserLoggedIn),
              setUserData: props.reduxfunction.setUserData,
              userRole: props$1.userRole,
              providerRole: props$1.providerRole
            });
}

var $$default = LocationShow$default;

export {
  LocationShow ,
  $$default as default,
}
/* css Not a pure module */
